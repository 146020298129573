import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

import { useLikeMap } from '../../modules/useLikeMap'
import CssProperties from '../../mixins/cssProperties'

import LikeIcon from '../atoms/icons/LikeIcon'
import NotLikeIcon from '../atoms/icons/NotLikeIcon'

interface IProps {
  createNumber: number
  title: string
}

export default (props: IProps) => {
  // お気に入りの記事情報を取得
  const { likeMap, handleClickLike } = useLikeMap()

  const iconContainerStyle: SerializedStyles = css({
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    bottom: '5px',
    right: '10px',
    zIndex: 100,
    svg: {
      height: '30px',
      color: CssProperties.colors.accentColor,
    },
  })

  return (
    <div
      css={iconContainerStyle}
      data-like={
        likeMap?.get(props.createNumber.toString()) !== undefined ? true : false
      }
      data-create-number={props.createNumber}
      data-title={props.title}
      onClick={handleClickLike}
    >
      {likeMap?.get(props.createNumber.toString()) !== undefined ? (
        <LikeIcon />
      ) : (
        <NotLikeIcon />
      )}
    </div>
  )
}
